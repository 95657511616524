<template>
  <div class="bg-beige">
    <loading-component class="mt-5" v-if="loading" />
    <div v-else>
      <error-component v-if="errored" :error-message="getErrorMessage"/>
      <div v-else class="mx-2">

        <div v-b-visible="handleVisible" class="d-block d-lg-none" style="position: fixed; z-index: -10; height: 1px; width: 1px; background-color: #F3F0EB"></div>

        <h5 class="mt-3 text-center">Jahresauswahl</h5>
        <b-pagination :key="componentKey" v-model="currentPageYears" :total-rows="years.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
          <template #page="{ page }">
            <span v-if="page < years.length">{{ page + years[0] - 1}}</span>
            <span v-else>{{ years[page-1]}}</span>
          </template>
        </b-pagination>

        <h3 class="mt-4">Visualisierung Jahreswerte
          <svg v-b-tooltip.hover.bottom title="Die nachfolgenden Diagramme zeigen die generierten Umsätze je Monat. Hierbei werden nur abgeschlossene Aufträge betrachtet. Outbound Deals sind Deals, die durch Werbepartner angefragt wurden, Inbound Deals sind durch uns initiiert." width="20" height="20" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
        </h3>
        <b-card
            class="mb-2 bg-beige"
        >
          <div class="m-2">
            Im ausgewählten Jahr wurde ein Gesamtumsatz in Höhe von <b>{{gesamt_umsatz.toLocaleString("de-DE", {style: "currency", currency: "EUR", minimumFractionDigits: 0, maximumFractionDigits: 0})}}</b> generiert.
          </div>
          <div class="row mt-5">
            <loading-component v-if="barChartData === null"/>
            <BarChartComponent v-else :chart-data="barChartData" class="col-lg-8 col-md-12" />
            <loading-component v-if="pieChartData === null"/>
            <PieChartComponent :chart-data="pieChartData" class="col-lg-4 col-md-12" />
          </div>
          <div class="row mt-5">
            <loading-component v-if="lineChartData === null"/>
            <LineChartComponent v-else :chart-data="lineChartData" class="col-lg-8 col-md-12" />
            <loading-component v-if="CashflowData === null"/>
            <CashflowComponent :dealId="dealId" :cashflow-data="CashflowData" class="col-lg-4 col-md-12" />
          </div>
        </b-card>

        <!--
        <h5 class="mt-3 text-center">Jahresauswahl</h5>
        <div class="d-flex justify-content-center align-items-center flex-wrap">
          <div class="p-2">
            <b-pagination v-model="currentPageYears" :total-rows="years.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
              <template #page="{ page }">
                <span v-if="page < years.length">{{ page + years[0] - 1}}</span>
                <span v-else>{{ years[page-1]}}</span>
              </template>
            </b-pagination>
          </div>
          <div class="p-2"><b-button variant="primary" @click="selectAllYears()">Alle Jahre</b-button></div>
        </div>
        -->

        <h3 class="mt-3">Übersicht aller Deals
          <svg v-b-tooltip.hover title="Hier werden alle Deals des oben gewählten Jahres angezeigt. Aufgeschlüsselt wird nach gewähltem Monat oder über das gesamte Jahr. Outbound Deals sind Deals, die durch Werbepartner angefragt wurden, Inbound Deals sind durch uns initiiert." width="20" height="20" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
          </svg>
        </h3>
        <b-card no-body>
          <b-tabs card fill>
            <b-tab title="Gesamt" v-if="dealId == '294b0e98dcfd3b98b82ba6668cafad6c0d6b983c5eaf07dad20475d360b082fd'">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                <h5 class="mt-3 text-center">Monatsauswahl</h5>
                <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                  <template #page="{ page }">
                    <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                    <span v-else>{{ months[page-1]}}</span>
                  </template>
                </b-pagination>
                </div>

                <CategoryComponent :deals="filteredData" :key="componentKey" :tabs="'alleDeals'" :pipeline="'alle'"/>
              </b-card-text>
            </b-tab>
            <b-tab title="Inbound" active>
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                <h5 class="mt-3 text-center">Monatsauswahl</h5>
                <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                  <template #page="{ page }">
                    <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                    <span v-else>{{ months[page-1]}}</span>
                  </template>
                </b-pagination>
                </div>

                <CategoryComponent :deals="getInboundDeals" :key="componentKey" :tabs="'alleDeals'" :pipeline="'inbound'"/>
              </b-card-text>
            </b-tab>
            <b-tab title="Outbound">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                <h5 class="mt-3 text-center">Monatsauswahl</h5>
                <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                  <template #page="{ page }">
                    <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                    <span v-else>{{ months[page-1]}}</span>
                  </template>
                </b-pagination>
                </div>

                <CategoryComponent :deals="getOutboundDeals" :key="componentKey" :tabs="'alleDeals'" :pipeline="'outbound'"/>
              </b-card-text>
            </b-tab>
            <b-tab :title="'' +
             'Deals Won (' + child_var1 + ')'">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                <h5 class="mt-3 text-center">Monatsauswahl</h5>
                <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                  <template #page="{ page }">
                    <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                    <span v-else>{{ months[page-1]}}</span>
                  </template>
                </b-pagination>
                </div>

                <!--<CategoryComponent :deals="getLostDeals" :key="componentKey" :pipeline="'lost'" @sum_lost_deals="handleLostEvent"/>-->
                <CategoryComponent :deals="getWonDeals" :key="componentKey" :tabs="'alleDeals'" :pipeline="'won'" @sum_won_deals="handleWonEvent"/>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>

        <br>

        <div v-if="dealId == '294b0e98dcfd3b98b82ba6668cafad6c0d6b983c5eaf07dad20475d360b082fd' && false">
          <h3 class="mt-3">Deals, wo Influencer nicht im Dashboard
            <svg v-b-tooltip.hover title="Hier werden alle Deals des oben gewählten Jahres angezeigt. Aufgeschlüsselt wird nach gewähltem Monat oder über das gesamte Jahr. Outbound Deals sind Deals, die durch Werbepartner angefragt wurden, Inbound Deals sind durch uns initiiert." width="20" height="20" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
            </svg>
          </h3>
        </div>
        <b-card no-body v-if="dealId == '294b0e98dcfd3b98b82ba6668cafad6c0d6b983c5eaf07dad20475d360b082fd' && false">
          <b-tabs card fill>
            <b-tab title="Gesamt">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                  <h5 class="mt-3 text-center">Monatsauswahl</h5>
                  <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                    <template #page="{ page }">
                      <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                      <span v-else>{{ months[page-1]}}</span>
                    </template>
                  </b-pagination>
                </div>

                <CategoryComponent :deals="filteredOtherData" :key="componentKey" :tabs="'other'" :pipeline="'alle'"/>
              </b-card-text>
            </b-tab>
            <b-tab title="Inbound" active>
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                  <h5 class="mt-3 text-center">Monatsauswahl</h5>
                  <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                    <template #page="{ page }">
                      <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                      <span v-else>{{ months[page-1]}}</span>
                    </template>
                  </b-pagination>
                </div>

                <CategoryComponent :deals="getInboundOtherDeals" :key="componentKey" :tabs="'other'" :pipeline="'inbound'"/>
              </b-card-text>
            </b-tab>
            <b-tab title="Outbound">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                  <h5 class="mt-3 text-center">Monatsauswahl</h5>
                  <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                    <template #page="{ page }">
                      <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                      <span v-else>{{ months[page-1]}}</span>
                    </template>
                  </b-pagination>
                </div>

                <CategoryComponent :deals="getOutboundOtherDeals" :key="componentKey" :tabs="'other'" :pipeline="'outbound'"/>
              </b-card-text>
            </b-tab>
            <b-tab :title="'' +
             'Deals Won (' + child_var2 + ')'">
              <b-card-text>
                <div v-if="false && currentPageYears !== years.length">
                  <h5 class="mt-3 text-center">Monatsauswahl</h5>
                  <b-pagination class="mb-5" :limit=13 v-model="currentPageMonths" :total-rows="months.length" :per-page="1" :align="isBreakPointLg === true ? 'center' : 'fill'" style="margin-bottom: 0px;" first-number last-number>
                    <template #page="{ page }">
                      <span v-if="page < months.length">{{ page + months[0] - 1}}</span>
                      <span v-else>{{ months[page-1]}}</span>
                    </template>
                  </b-pagination>
                </div>

                <!--<CategoryComponent :deals="getLostOtherDeals" :key="componentKey" :pipeline="'lost'" @sum_lost_deals="handleOtherLostEvent"/>-->
                <CategoryComponent :deals="getWonOtherDeals" :key="componentKey" :tabs="'other'" :pipeline="'won'" @sum_won_deals="handleOtherWonEvent"/>
              </b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllDeals, getAllOtherDeals } from '../client/index'
import BarChartComponent from '@/components/BarChartComponent'
import PieChartComponent from '@/components/PieChartComponent'
import LineChartComponent from '@/components/LineChartComponent'
import CashflowComponent from '@/components/CashflowComponent'
import CategoryComponent from '@/components/CategoryComponent'
import ErrorComponent from '@/components/ErrorComponent'
import LoadingComponent from '@/components/LoadingComponent'
import moment from 'moment'
export default {
  name: 'Dashboard-View',
  components: { LoadingComponent, ErrorComponent, CategoryComponent, BarChartComponent, PieChartComponent, LineChartComponent, CashflowComponent },
  props: ['dealId', 'id'],
  data () {
    return {
      userToken: '',
      password: '',
      loading: true,
      error: null,
      errored: false,
      data: null,
      filteredData: null,
      filteredOtherData: null,
      years: null,
      months: null,
      errorMessage: 'Beim Laden der Deals trat ein Fehler auf.',
      barChartData: null,
      pieChartData: null,
      lineChartData: null,
      CashflowData: null,
      currentPageYears: null,
      currentPageMonths: null,
      isBreakPointLg: null,
      componentKey: 0,
      gesamt_umsatz: 0,
      child_var1: null,
      child_var2: null
    }
  },
  beforeMount () {
    getAllDeals(this.dealId, (data, error) => {
      this.loading = false
      if (error) {
        this.errored = true
        this.error = error
        console.log('comp errored!')
      }
      if (data) {
        this.data = data.deals
        this.filteredData = data.deals
        data.years.push('Alle')
        this.years = data.years
        this.currentPageYears = this.years.length - 1
        this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Alle']
        this.currentPageMonths = 13 // moment().month() + 1
        this.barChartData = this.getBarChartData(
          this.getFullYearNumbers(this.getInboundDealsRechnung),
          this.getFullYearNumbers(this.getOutboundDealsRechnung),
          this.getFullYearNumbers(this.filteredData.filter((deal) => deal.Kategorie === '3')), this.years[this.years.length - 1]
        )
        this.pieChartData = this.getPieChartData(
          this.getSumOfBetrag(this.getInboundDealsRechnung),
          this.getSumOfBetrag(this.getOutboundDealsRechnung)
        )
        this.lineChartData = this.getLineChartData(
          this.getSumOfBetrag(this.getFullYearNumbersDeals(this.filteredData.filter((deal) => deal.Kategorie === '3')), this.years[this.years.length - 1])
        )
        this.CashflowData = this.filteredData.filter((deal) => (deal.Kategorie === '3' && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 9960 && ['zu Finance', 'Rechnung an Brand geschickt', 'Geld bei Influencer / For You', 'Geld bei For You', 'Rechnung Schreiben', 'Rechnung Verschickt', 'Geld Eingenommen'].includes(deal.Deal_Phase)))
      }
    })
    getAllOtherDeals(this.dealId, (data, error) => {
      this.loading = false
      if (error) {
        this.errored = true
        this.error = error
        console.log('comp errored!')
      }
      if (data) {
        this.filteredOtherData = data.deals
      }
    })
  },
  computed: {
    getInboundDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Inbound' && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Inbound'))
      }
    },
    getOutboundDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Outbound' && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Outbound'))
      }
    },
    getLostDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredData.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Outbound'))
      }
    },
    getWonDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredData.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredData.filter((deal) => (deal.Pipeline === 'Outbound' || deal.Pipeline === 'Inbound' || deal.Pipeline !== ''))
      }
    },
    getInboundDealsRechnung () {
      return this.filteredData.filter((deal) => (deal.InOutbound === 'Inbound' && deal.Kategorie === '3'))
    },
    getOutboundDealsRechnung () {
      return this.filteredData.filter((deal) => (deal.InOutbound === 'Outbound' && deal.Kategorie === '3'))
    },
    getInboundOtherDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Inbound' && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Inbound'))
      }
    },
    getOutboundOtherDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Outbound' && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Outbound'))
      }
    },
    getLostOtherDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredOtherData.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Outbound'))
      }
    },
    getWonOtherDeals () {
      if (this.currentPageMonths !== 13) {
        return this.filteredOtherData.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      } else {
        return this.filteredOtherData.filter((deal) => (deal.Pipeline === 'Outbound' || deal.Pipeline === 'Inbound'))
      }
    },
    getErrorMessage () {
      return this.errorMessage + ' - ID: ' + this.$route.params.id + ' | ' + this.error
    },
    triggerReload () {
      return this.dealId
    }
  },
  methods: {
    getFullYearNumbers (deals) {
      // const quartals = [['01', '02', '03'], ['04', '05', '06'], ['07', '08', '09'], ['10', '11', '12']]
      const quartals = [['01'], ['02'], ['03'], ['04'], ['05'], ['06'], ['07'], ['08'], ['09'], ['10'], ['11'], ['12']]

      return quartals.map((quartal) => {
        const quartalDeals = this.getQuartalDeals(quartal, deals)
        return this.getSumOfBetrag(quartalDeals)
      })
    },
    getFullYearNumbersDeals (deals) {
      // const quartals = [['01', '02', '03'], ['04', '05', '06'], ['07', '08', '09'], ['10', '11', '12']]
      const quartals = [['01'], ['02'], ['03'], ['04'], ['05'], ['06'], ['07'], ['08'], ['09'], ['10'], ['11'], ['12']]

      return quartals.map((quartal) => {
        const quartalDeals = this.getQuartalDeals(quartal, deals)
        return this.getSumOfDeals(quartalDeals)
      })
    },
    getQuartalDeals (quartal, deals) {
      return deals.filter((deal) => {
        const date = deal.Datum_der_letzten_Aenderung.split(' ')[0]
        const month = date.split('-')[1]
        // return month === quartal[0] || month === quartal[1] || month === quartal[2] || month === quartal[3]
        return month === quartal[0] || month === quartal[1] || month === quartal[2] || month === quartal[3] || month === quartal[4] || month === quartal[5] || month === quartal[6] || month === quartal[7] || month === quartal[8] || month === quartal[9] || month === quartal[10] || month === quartal[11]
      })
    },
    getSumOfBetrag (deals) {
      const array = deals.map((deal) => {
        if (deal.Betrag !== null && deal.Betrag !== '') {
          return deal.Betrag
        }
        return deal.Betrag
      })

      return array.reduce((accumulator, value) => {
        return accumulator + +value
      }, 0)
    },
    getSumOfDeals (deals) {
      const array = deals.map((deal) => {
        if (deal.Betrag !== null && deal.Betrag !== '') {
          return 1
        }
        return 0
      })

      return array.reduce((accumulator, value) => {
        return accumulator + +value
      }, 0)
    },
    getBarChartData (dataInbound, dataOutbound, data, year) {
      var labels = [
        'Jan/' + year,
        'Feb/' + year,
        'Mär/' + year,
        'Apr/' + year,
        'Mai/' + year,
        'Jun/' + year,
        'Jul/' + year,
        'Aug/' + year,
        'Sep/' + year,
        'Okt/' + year,
        'Nov/' + year,
        'Dez/' + year
      ]
      const currentYear = (new Date()).getFullYear()
      const currentMonth = (new Date()).getMonth()
      if (currentYear === year && currentMonth > 2) {
        labels = labels.slice(0, currentMonth + 1)
      }
      return {
        labels: labels,
        datasets: [
          {
            label: 'Inbound',
            backgroundColor: '#90e0ef',
            data: dataInbound
          }, {
            label: 'Outbound',
            backgroundColor: '#0096c7',
            data: dataOutbound
          }, {
            label: 'Gesamt',
            backgroundColor: '#f87979',
            data: data
          }
        ]
      }
    },
    getPieChartData (dataInbound, dataOutbound) {
      console.log(dataInbound, dataOutbound, 'darko')
      return {
        labels: ['Inbound', 'Outbound'],
        datasets: [
          {
            backgroundColor: ['#90e0ef', '#0096c7'],
            data: [dataInbound, dataOutbound]
          }
        ]
      }
    },
    getLineChartData (data, year) {
      var labels = [
        'Jan/' + year,
        'Feb/' + year,
        'Mär/' + year,
        'Apr/' + year,
        'Mai/' + year,
        'Jun/' + year,
        'Jul/' + year,
        'Aug/' + year,
        'Sep/' + year,
        'Okt/' + year,
        'Nov/' + year,
        'Dez/' + year
      ]
      const currentYear = (new Date()).getFullYear()
      const currentMonth = (new Date()).getMonth()
      if (currentYear === year && currentMonth > 2) {
        labels = labels.slice(0, currentMonth + 1)
      }
      return {
        labels: labels,
        datasets: [
          {
            label: 'Anzahl Deals',
            backgroundColor: '#f87979',
            data: data
          }
        ]
      }
    },
    refreshDashboardCharts (year) {
      if (year === 0) {
        this.filteredData = this.data
        // this.currentPageMonths = 13
        year = 'Gesamt'
      } else {
        this.filteredData = this.data.filter((deal) => moment(deal.Datum_der_letzten_Aenderung).year() === year)
        this.filteredDataUmsatz = this.data.filter((deal) => moment(deal.Datum_der_letzten_Aenderung).year() === year)
      }
      // this.gesamt_umsatz = this.getSumOfBetrag(this.filteredDataUmsatz.filter((deal) => deal.Kategorie === '3' && ['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase)))
      this.gesamt_umsatz = this.getSumOfBetrag(this.filteredDataUmsatz.filter((deal) => deal.Kategorie === '3'))
      // const umsatzdata = this.filteredDataUmsatz.filter((deal) => deal.Kategorie === '3' && ['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase))
      const umsatzdata = this.filteredDataUmsatz.filter((deal) => deal.Kategorie === '3')
      // Loop through the outer object
      for (var personKey in umsatzdata) {
        // Access the inner object for the current person
        var personObject = umsatzdata[personKey]

        // Loop through the properties of the inner object
        for (var innerKey in personObject) {
          // Check if the current property is the one you want
          if (innerKey === 'Record_ID') {
            // Print the value for the specified attribute
            // console.log(personObject[innerKey]) // print record id
            break // Optional: If you found the attribute, you can exit the inner loop
          }
        }
      }
      this.barChartData = this.getBarChartData(
        this.getFullYearNumbers(this.getInboundDealsRechnung),
        this.getFullYearNumbers(this.getOutboundDealsRechnung),
        this.getFullYearNumbers(this.filteredData.filter((deal) => deal.Kategorie === '3')), year
      )
      this.pieChartData = this.getPieChartData(
        this.getSumOfBetrag(this.getInboundDealsRechnung),
        this.getSumOfBetrag(this.getOutboundDealsRechnung)
      )
      this.lineChartData = this.getLineChartData(
        this.getFullYearNumbersDeals(this.filteredData.filter((deal) => deal.Kategorie === '3')), year
      )
      this.CashflowData = this.filteredData.filter((deal) => (deal.Kategorie === '3' && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 9960 && ['zu Finance', 'Rechnung an Brand geschickt', 'Geld bei Influencer / For You', 'Geld bei For You', 'Rechnung Schreiben', 'Rechnung Verschickt', 'Geld Eingenommen'].includes(deal.Deal_Phase)))
      if (year !== 'Gesamt') {
        this.filteredData = this.data.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).year() === year && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === this.months[this.currentPageMonths - 1]))
      }
    },
    selectAllYears () {
      this.refreshDashboardCharts(0)
    },
    refreshDashboardDeals (month) {
      if (month === 0) {
        this.filteredData = this.data.filter((deal) => moment(deal.Datum_der_letzten_Aenderung).year() === this.years[this.currentPageYears - 1])
        // console.log(this.filteredData)
        month = 'Gesamt'
      } else {
        this.filteredData = this.data.filter((deal) => (moment(deal.Datum_der_letzten_Aenderung).year() === this.years[this.currentPageYears - 1] && moment(deal.Datum_der_letzten_Aenderung).month() + 1 === month))
        // console.log('month:', month, moment(this.filteredData[0].Erstellungsdatum).month() + 1, this.months[this.currentPageMonths - 1])
      }
    },
    selectAllMonths () {
      this.refreshDashboardDeals(0)
    },
    handleVisible (isVisible) {
      this.isBreakPointLg = !isVisible
      // console.log(this.isBreakPointLg)
    },
    forceRerender () {
      this.componentKey += 1
    },
    reloadView () {
      var years = null
      getAllDeals(this.dealId, (data, error) => {
        this.loading = false
        if (error) {
          this.errored = true
          this.error = error
          console.log('comp errored!')
        }
        if (data) {
          this.data = data.deals
          this.filteredData = data.deals
          data.years.push('Alle')
          this.years = data.years
          // years = this.years.length - 1
          this.currentPageYears = this.years.length - 1
          this.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 'Alle']
          this.currentPageMonths = 13 // moment().month() + 1
          this.barChartData = this.getBarChartData(
            this.getFullYearNumbers(this.getInboundDealsRechnung),
            this.getFullYearNumbers(this.getOutboundDealsRechnung),
            this.getFullYearNumbers(this.filteredData.filter((deal) => deal.Kategorie === '3')), this.years[this.years.length - 1]
          )
          this.pieChartData = this.getPieChartData(
            this.getSumOfBetrag(this.getInboundDealsRechnung),
            this.getSumOfBetrag(this.getOutboundDealsRechnung)
          )
          this.lineChartData = this.getLineChartData(
            this.getSumOfBetrag(this.getFullYearNumbersDeals(this.filteredData.filter((deal) => deal.Kategorie === '3')), this.years[this.years.length - 1])
          )
          this.CashflowData = this.filteredData.filter((deal) => (deal.Kategorie === '3' && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 9960 && ['zu Finance', 'Rechnung an Brand geschickt', 'Geld bei Influencer / For You', 'Geld bei For You', 'Rechnung Schreiben', 'Rechnung Verschickt', 'Geld Eingenommen'].includes(deal.Deal_Phase)))
        }
      })
      getAllOtherDeals(this.dealId, (data, error) => {
        this.loading = false
        if (error) {
          this.errored = true
          this.error = error
          console.log('comp errored!')
        }
        if (data) {
          this.filteredOtherData = data.deals
        }
      })
      return years
    },
    handleLostEvent (data) {
      this.child_var1 = data
    },
    handleWonEvent (data) {
      this.child_var1 = data
    },
    handleOtherLostEvent (data) {
      this.child_var2 = data
    },
    handleOtherWonEvent (data) {
      this.child_var2 = data
    }
  },
  watch: {
    currentPageYears: function (pageVal) {
      console.log('Change to page', pageVal)
      if (pageVal !== null && pageVal !== this.years.length) {
        this.refreshDashboardCharts(pageVal + this.years[0] - 1)
      }
      if (pageVal !== null && pageVal === this.years.length) {
        this.selectAllYears()
      }
      if (pageVal !== null && this.currentPageMonths === 13 && pageVal !== this.years.length) {
        this.refreshDashboardDeals(0)
      }
      this.forceRerender()
    },
    currentPageMonths: function (pageVal) {
      // console.log('Change to page', this.months[this.currentPageMonths - 1], pageVal + this.months[0] - 1)
      if (pageVal !== null && pageVal !== this.months.length) {
        this.refreshDashboardDeals(pageVal + this.months[0] - 1)
      }
      if (pageVal !== null && pageVal === this.months.length) {
        this.selectAllMonths()
      }
      this.forceRerender()
    },
    triggerReload () {
      this.reloadView()
      this.currentPageYears += 0.1
      // this.currentPageYears = years + 1
      // this.currentPageYears = years
    }
  }
}
</script>

<style>
.bg-beige {
  background-color: #F3F0EB !important;
}

.page-link {
  background-color: #2e2e2e !important;
  border-color: #464646 !important;
  color: #FFF !important;
}

.page-link:hover {
  background-color: #353535 !important;
}

.pagination > li.active > button {
  background: #EC5500 !important;
  color: #FFF !important;
  border-color: #EC5500 !important;
}
</style>
