<template>
  <b-alert variant="danger" show>{{errorMessage}}</b-alert>
</template>

<script>
export default {
  name: 'ErrorComponent',
  props: ['errorMessage']
}
</script>

<style scoped>

</style>
