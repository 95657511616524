<template>
  <div>
    <!-- tabs=alledeals -->
    <div v-if="tabs === 'alleDeals'">
  <div v-if="pipeline === 'outbound' || pipeline === 'inbound' || pipeline === 'alle'">
    <div class="d-flex flex-column" v-if="pipeline === 'outbound'">
      <b-button v-b-toggle.collapse--1 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die wir anschreiben ({{getCat_1.length}})</b-button>
      <b-collapse id="collapse--1" class="mt-2">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_1" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="my-5 d-flex flex-column" v-if="pipeline === 'outbound' || pipeline === 'inbound'">
      <b-button v-b-toggle.collapse--3 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals in Verhandlung ({{getCat_3.length}})</b-button>
      <b-collapse id="collapse--3" class="mt-2">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_3" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="d-flex flex-column" v-if="pipeline === 'inbound'">
      <b-button v-b-toggle.collapse-4 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die dich angeschrieben haben ({{getCat4.length}})</b-button>
      <b-collapse id="collapse-4" class="mt-2">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat4" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="my-5 d-flex flex-column">
      <b-button v-b-toggle.collapse-1 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die kurz vor Abschluss sind ({{getCat1.length}})</b-button>
      <b-collapse id="collapse-1" class="mt-2">
        <div class="d-flex flex-wrap justify-content-center">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat1" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="my-5 d-flex flex-column">
      <b-button v-b-toggle.collapse-2 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die am Laufen sind ({{getCat2[0].length}})</b-button>
      <b-collapse id="collapse-2" class="mt-2">
        <div class="d-flex justify-content-center">
          <div class="p-2">
          <b-form-checkbox v-model="isTurnoverFilterActiveCat2" name="check-button-cat2" switch>
            {{ getCurrentCat2Filter }}
          </b-form-checkbox>
          </div>
        </div>
        <transition name="fade" mode="out-in">
        <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat2">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat2[0]" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
        </transition>
        <div class="d-flex justify-content-center">
          <div class="p-2">
            <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
              <b-list-group flush>
                <b-list-group-item class="">Dealanzahl: {{getCat2[0].length}}</b-list-group-item>
                <b-list-group-item class="">Gesamtbetrag: {{getCat2[1]}}€</b-list-group-item>
                <b-list-group-item class="">&#8709; {{getCat2[2]}}€</b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="my-5 d-flex flex-column">
      <b-button v-b-toggle.collapse-3 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, wo Rechnung geschrieben ({{getCat3[0].length}})</b-button>
      <b-collapse id="collapse-3" class="mt-2">
        <div class="d-flex justify-content-center">
          <div class="p-2">
          <b-form-checkbox v-model="isTurnoverFilterActiveCat3" name="check-button-cat3" switch>
            {{ getCurrentCat3Filter }}
          </b-form-checkbox>
          </div>
        </div>
        <transition name="fade" mode="out-in">
        <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat3">
          <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat3[0]" :key="index">
          <DealComponent :deal="deal"/>
          </div>
        </div>
        </transition>
        <div class="d-flex justify-content-center">
          <div class="p-2">
            <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
              <b-list-group flush>
                <b-list-group-item class="">Dealanzahl: {{getCat3[0].length}}</b-list-group-item>
                <b-list-group-item class="">Gesamtbetrag: {{getCat3[1]}}€</b-list-group-item>
                <b-list-group-item class="">&#8709; {{getCat3[2]}}€</b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </div>
      </b-collapse>
    </div>

    <div class="d-flex flex-column">
      <b-button v-b-toggle.collapse-2_2 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals lost ({{getCat_2.length}})</b-button>
      <b-collapse id="collapse-2_2" class="mt-2">
        <div class="d-flex justify-content-center">
          <div class="p-2">
          </div>
        </div>
        <transition name="fade" mode="out-in">
          <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat3">
            <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2" :key="index">
              <DealComponent :deal="deal"/>
            </div>
          </div>
        </transition>
        <div class="d-flex justify-content-center">
          <div class="p-2">
            <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
              <b-list-group flush>
                <b-list-group-item class="">Dealanzahl: {{getCat_2.length}}</b-list-group-item>
              </b-list-group>
            </b-card>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>

  <div v-if="pipeline === 'lost'">
    <div class='d-flex flex-column'>
      <div class="d-flex flex-wrap justify-content-center">
        <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2" :key="index">
          <DealComponent :deal="deal"/>
        </div>
      </div>
    </div>
  </div>
  <div v-if="pipeline === 'won'">
    <div class='d-flex flex-column'>
      <div class="d-flex flex-wrap justify-content-center">
        <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2_2[0]" :key="index">
          <DealComponent :deal="deal"/>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <div class="p-2">
          <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
            <b-list-group flush>
              <b-list-group-item class="">Dealanzahl: {{getCat_2_2[0].length}}</b-list-group-item>
              <b-list-group-item class="">Gesamtbetrag: {{getCat_2_2[1]}}€</b-list-group-item>
              <b-list-group-item class="">&#8709; {{getCat_2_2[2]}}€</b-list-group-item>
            </b-list-group>
          </b-card>
        </div>
      </div>
    </div>
  </div>
    </div>

    <!-- tabs = other -->
    <div v-if="tabs === 'other'">
      <div v-if="pipeline === 'outbound' || pipeline === 'inbound' || pipeline === 'alle'">
        <div class="d-flex flex-column" v-if="pipeline === 'outbound'">
          <b-button v-b-toggle.collapse-other--1 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die wir anschreiben ({{getCat_1.length}})</b-button>
          <b-collapse id="collapse-other--1" class="mt-2">
            <div class="d-flex flex-wrap justify-content-center">
              <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_1" :key="index">
                <DealComponent :deal="deal"/>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="my-5 d-flex flex-column" v-if="pipeline === 'outbound'">
          <b-button v-b-toggle.collapse-other--3 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals in Verhandlung ({{getCat_3.length}})</b-button>
          <b-collapse id="collapse-other--3" class="mt-2">
            <div class="d-flex flex-wrap justify-content-center">
              <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_3" :key="index">
                <DealComponent :deal="deal"/>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="d-flex flex-column" v-if="pipeline === 'inbound'">
          <b-button v-b-toggle.collapse-other-4 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die dich angeschrieben haben ({{getCat4.length}})</b-button>
          <b-collapse id="collapse-other-4" class="mt-2">
            <div class="d-flex flex-wrap justify-content-center">
              <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat4" :key="index">
                <DealComponent :deal="deal"/>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="my-5 d-flex flex-column">
          <b-button v-b-toggle.collapse-other-1 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die kurz vor Abschluss sind ({{getCat1.length}})</b-button>
          <b-collapse id="collapse-other-1" class="mt-2">
            <div class="d-flex flex-wrap justify-content-center">
              <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat1" :key="index">
                <DealComponent :deal="deal"/>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="my-5 d-flex flex-column">
          <b-button v-b-toggle.collapse-other-2 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, die am Laufen sind ({{getCat2[0].length}})</b-button>
          <b-collapse id="collapse-other-2" class="mt-2">
            <div class="d-flex justify-content-center">
              <div class="p-2">
                <b-form-checkbox v-model="isTurnoverFilterActiveCat2" name="check-button-cat2" switch>
                  {{ getCurrentCat2Filter }}
                </b-form-checkbox>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat2">
                <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat2[0]" :key="index">
                  <DealComponent :deal="deal"/>
                </div>
              </div>
            </transition>
            <div class="d-flex justify-content-center">
              <div class="p-2">
                <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
                  <b-list-group flush>
                    <b-list-group-item class="">Dealanzahl: {{getCat2[0].length}}</b-list-group-item>
                    <b-list-group-item class="">Gesamtbetrag: {{getCat2[1]}}€</b-list-group-item>
                    <b-list-group-item class="">&#8709; {{getCat2[2]}}€</b-list-group-item>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="my-5 d-flex flex-column">
          <b-button v-b-toggle.collapse-other-3 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals, wo Rechnung geschrieben ({{getCat3[0].length}})</b-button>
          <b-collapse id="collapse-other-3" class="mt-2">
            <div class="d-flex justify-content-center">
              <div class="p-2">
                <b-form-checkbox v-model="isTurnoverFilterActiveCat3" name="check-button-cat3" switch>
                  {{ getCurrentCat3Filter }}
                </b-form-checkbox>
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat3">
                <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat3[0]" :key="index">
                  <DealComponent :deal="deal"/>
                </div>
              </div>
            </transition>
            <div class="d-flex justify-content-center">
              <div class="p-2">
                <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
                  <b-list-group flush>
                    <b-list-group-item class="">Dealanzahl: {{getCat3[0].length}}</b-list-group-item>
                    <b-list-group-item class="">Gesamtbetrag: {{getCat3[1]}}€</b-list-group-item>
                    <b-list-group-item class="">&#8709; {{getCat3[2]}}€</b-list-group-item>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-collapse>
        </div>

        <div class="d-flex flex-column">
          <b-button v-b-toggle.collapse-other-2_2 variant="outline-secondary" class="col-12 col-md-4 align-self-center">Deals lost ({{getCat_2.length}})</b-button>
          <b-collapse id="collapse-other-2_2" class="mt-2">
            <div class="d-flex justify-content-center">
              <div class="p-2">
              </div>
            </div>
            <transition name="fade" mode="out-in">
              <div class="d-flex flex-wrap justify-content-center" :key="isTurnoverFilterActiveCat3">
                <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2" :key="index">
                  <DealComponent :deal="deal"/>
                </div>
              </div>
            </transition>
            <div class="d-flex justify-content-center">
              <div class="p-2">
                <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
                  <b-list-group flush>
                    <b-list-group-item class="">Dealanzahl: {{getCat_2.length}}</b-list-group-item>
                  </b-list-group>
                </b-card>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>

      <div v-if="pipeline === 'lost'">
        <div class='d-flex flex-column'>
          <div class="d-flex flex-wrap justify-content-center">
            <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2" :key="index">
              <DealComponent :deal="deal"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="pipeline === 'won'">
        <div class='d-flex flex-column'>
          <div class="d-flex flex-wrap justify-content-center">
            <div class="col-12 col-md-3 m-3" v-for="(deal, index) in getCat_2_2[0]" :key="index">
              <DealComponent :deal="deal"/>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="p-2">
              <b-card bg-variant="secondary" text-variant="white" header="Zusammenfassung" class="text-center" no-body>
                <b-list-group flush>
                  <b-list-group-item class="">Dealanzahl: {{getCat_2_2[0].length}}</b-list-group-item>
                  <b-list-group-item class="">Gesamtbetrag: {{getCat_2_2[1]}}€</b-list-group-item>
                  <b-list-group-item class="">&#8709; {{getCat_2_2[2]}}€</b-list-group-item>
                </b-list-group>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DealComponent from '@/components/DealComponent'
import moment from 'moment'
export default {
  name: 'CategoryComponent',
  components: { DealComponent },
  props: ['deals', 'pipeline', 'tabs'],
  data () {
    return {
      turnoverFilter: 'nach Umsatz sortiert',
      dateFilter: 'nach Datum sortiert',
      isTurnoverFilterActiveCat1: false,
      isTurnoverFilterActiveCat2: false,
      isTurnoverFilterActiveCat3: false
    }
  },
  computed: {
    getCat_1 () {
      function customSort (a, b) {
        return new Date(a.Erstellungsdatum).getTime() - new Date(b.Erstellungsdatum).getTime()
      }
      return this.deals.filter((deal) => deal.Kategorie === '-1' && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 90).sort(customSort).reverse()
    },
    getCat_2 () {
      function customSort (a, b) {
        return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
      }
      if (this.tabs === 'other') {
        const deals = this.deals.filter((deal) => deal.Kategorie === '-2').sort(customSort).reverse()
        return deals
      } else {
        const deals = this.deals.filter((deal) => deal.Kategorie === '-2' && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 31).sort(customSort).reverse()
        return deals
      }
    },
    getCat_2_2 () {
      function customSort (a, b) {
        return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
      }
      const deals = this.deals.filter((deal) => deal.Kategorie === '3').sort(customSort).reverse()
      var sum = 0
      for (var deal of deals) {
        sum += parseFloat(deal.Betrag) || 0
      }
      const avg = Math.round((sum / deals.length) * 100) / 100
      const arr = [deals, sum.toLocaleString('de-DE'), avg.toLocaleString('de-DE')]
      return arr
    },
    getCat_3 () {
      function customSort (a, b) {
        return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
      }
      const deals = this.deals.filter((deal) => deal.Kategorie === '-3').sort(customSort).reverse()
      return deals
    },
    getCat1 () {
      const pipeline = this.pipeline
      if (pipeline === 'outbound') {
        return this.deals.filter((deal) => deal.Kategorie === '1')
      }
      if (pipeline === 'inbound') {
        return this.deals.filter((deal) => deal.Kategorie === '1')
      } else {
        return this.deals.filter((deal) => (deal.Kategorie === '-1' || deal.Kategorie === '1'))
      }
    },
    getCat2 () {
      const filterUmsatz = this.isTurnoverFilterActiveCat2
      function customSort (a, b) {
        if (filterUmsatz) {
          return a.Betrag - b.Betrag
        } else {
          return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
        }
      }
      const deals = this.deals.filter((deal) => deal.Kategorie === '2').sort(customSort).reverse()
      // const sum = Object.values(deals).reduce((acc, current) => acc + parseFloat(current.Betrag) || 0, 0)
      var sum = 0
      for (var deal of deals) {
        sum += parseFloat(deal.Betrag) || 0
      }
      const avg = Math.round((sum / deals.length) * 100) / 100
      const arr = [deals, sum.toLocaleString('de-DE'), avg.toLocaleString('de-DE')]
      return arr
    },
    getCat3 () {
      const filterUmsatz = this.isTurnoverFilterActiveCat3
      function customSort (a, b) {
        if (filterUmsatz) {
          return a.Betrag - b.Betrag
        } else {
          return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
        }
      }
      var dealsTmp
      if (this.tabs === 'other') {
        dealsTmp = this.deals.filter((deal) => ((deal.Kategorie === '3' && !['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase)))).sort(customSort).reverse()
        console.log(this.deals)
      } else {
        console.log('darko: ', this.deals)
        // dealsTmp = this.deals.filter((deal) => ((deal.Kategorie === '3' && !['Rechnung geschrieben (Won)', 'Geld bei For You'].includes(deal.Deal_Phase) && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 60))).sort(customSort).reverse()
        dealsTmp = this.deals.filter((deal) => ((deal.Kategorie === '3' && !['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase)))).sort(customSort).reverse()
      }
      const deals = dealsTmp
      // const deals = this.deals.filter((deal) => deal.Kategorie === '3').sort(customSort).reverse()

      var sum = 0
      for (var deal of deals) {
        sum += parseFloat(deal.Betrag) || 0
      }
      const avg = Math.round((sum / deals.length) * 100) / 100
      const arr = [deals, sum.toLocaleString('de-DE'), avg.toLocaleString('de-DE')]
      return arr
    },
    getCat4 () {
      function customSort (a, b) {
        return new Date(a.Datum_der_letzten_Aenderung).getTime() - new Date(b.Datum_der_letzten_Aenderung).getTime()
      }
      if (this.tabs === 'other') {
        const deals = this.deals.filter((deal) => ((deal.Kategorie === '4' && !['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase)))).sort(customSort).reverse()
        return deals
      } else {
        const deals = this.deals.filter((deal) => ((deal.Kategorie === '4' && !['Rechnung geschrieben (Won)', 'Geld bei For You', 'Geld Eingenommen', 'Geld Verteilt'].includes(deal.Deal_Phase) && moment().diff(moment(deal.Datum_der_letzten_Aenderung), 'days') < 90))).sort(customSort).reverse()
        return deals
      }
      // return this.deals.filter((deal) => deal.Kategorie === '4')
    },
    getCurrentCat1Filter () {
      if (this.isTurnoverFilterActiveCat1) {
        return this.turnoverFilter
      }
      return this.dateFilter
    },
    getCurrentCat2Filter () {
      if (this.isTurnoverFilterActiveCat2) {
        return this.turnoverFilter
      }
      return this.dateFilter
    },
    getCurrentCat3Filter () {
      if (this.isTurnoverFilterActiveCat3) {
        return this.turnoverFilter
      }
      return this.dateFilter
    }
  },
  mounted () {
    this.$emit('sum_lost_deals', this.getCat_2.length)
    this.$emit('sum_won_deals', this.getCat_2_2[0].length)
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: all .3s ease;
}
.fade-leave-active {
  transition: all .3s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
