import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const getDefaultState = {
  userToken: null,
  isAuthenticated: null,
  id: null
}

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: getDefaultState,
  getters: {
    isAuthenticated (state) {
      return state.isAuthenticated
    },
    getUserId (state) {
      return state.id
    }
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState)
    },
    setUserToken (state, userToken) {
      state.userToken = userToken
      state.isAuthenticated = !!userToken
    },
    setUserId (state, userId) {
      state.id = userId
    }
  },
  actions: {
    setUserToken ({ commit }, userToken) {
      commit('setUserToken', userToken)
    },
    setUserId ({ commit }, userId) {
      commit('setUserId', userId)
    }
  },
  modules: {
  }
})
