<template>
  <Line2
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as Line2 } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, CategoryScale, LinearScale)

export default {
  name: 'LineChartComponent',
  components: {
    Line2
  },
  props: {
    chartData: {
      type: Object
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: 'rgba(0, 0, 0, 0.5)',
        pointRadius: 8,
        pointHoverRadius: 10,
        pointHitRadius: 20,
        pointBorderWidth: 1,
        borderWidth: 5,
        plugins: {
          legend: {
            labels: {
              filter: function (legendItem, chartData) {
                if (legendItem.datasetIndex === 0) {
                  legendItem.lineWidth = 0
                }
                return true
              }
            }
          }
        },
        scales: {
          y: {
            grace: '5%',
            beginAtZero: true
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
