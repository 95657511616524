/* eslint-disable */
import axios from 'axios';
const FormData = require('form-data');

const mockError = "Beispielfehler!"

const protocol = "https://"
const host = "nifty-almeida.82-165-176-243.plesk.page/"

const storage = window.sessionStorage;
let userSessionToken = storage.getItem('userSessionToken');

function getAllDeals(id, callback) {
    const route = "get_deal_details3.php"
    const url = protocol+host+route+'?'+id

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function getAllOtherDeals(id, callback) {
    const route = "get_deal_details_other_deals.php"
    const url = protocol+host+route+'?'+id

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
            id: id
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
        .catch((error) => {
            // handle error
            console.log(error)
            callback(null, error)
        })
}

function loginUser(dealId, password, callback) {
    const route = 'session.php'

    console.log('UserID: ' + dealId)

    const credentials = dealId + ':' + password;
    const headerValue = window.btoa(unescape(encodeURIComponent(credentials)));
    const completeHeader = 'Basic ' + headerValue;

    axios.get(protocol+host+route, {
            headers: {
                    'Authorization': completeHeader,
                    'Access-Control-Allow-Headers': "*",
            }
    }).then((response) => {
            callback(response.data, null);
            const sessionToken = response.data.substring(1);
            storage.setItem('userSessionToken', sessionToken);
            userSessionToken = storage.getItem('userSessionToken');
    }).catch((error) => {
            callback(null, error)
            console.log(error);
    })
}

function setPW(a_benutzername, a_hash, callback) {
    const route = 'setpw.php'
    const formData = new FormData();

    formData.append("a_benutzername", a_benutzername);
    formData.append("a_hash", a_hash);

    axios.post(protocol+host+route, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
    }).then((response) => {
        callback(response.data, null);
        console.log(response);
    }).catch((error) => {
        callback(null, error)
        console.log(error);
    })
}

function getUrls(id, callback) {
    const route = 'get_urls2.php'
    const url = protocol+host+route+'?'+id

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function resetPW(id, id2, callback) {
    const route = 'resetpw.php'
    const url = protocol+host+route+'?'+id+id2
    console.log(url)

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id,
          id2: id2
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

function addInfluencer(id, influencer_name, callback) {
    const route = 'add_influencer.php'
    const url = protocol+host+route
    console.log(url)

    axios.get(url, {
        headers: {
            'Authorization': 'Bearer ' + userSessionToken
        },
        params: {
          id: id,
          influencer_name: influencer_name
        }
    }).then((response) => {
        // handle success
        callback(response.data, null)
        window.location.reload()
    })
    .catch((error) => {
        // handle error
        console.log(error)
        callback(null, error)
    })
}

export {
        getAllDeals, getAllOtherDeals, loginUser, setPW, getUrls, resetPW, addInfluencer
}
