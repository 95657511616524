<template>
    <footer>
      © For You Agency. Alle Rechte vorbehalten. <a href="https://www.foryouagency.de/impressum">Impressum</a> · <a href="https://www.foryouagency.de/datenschutz">Datenschutz</a>
    </footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  methods: {
  }
}
</script>

<style scoped>
.header {
  padding: 20px;
  position: relative;
}

.navbar {
  background-color: #2e2e2e !important;
}

.navbar-logo {
  width: 50px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #EC5500 !important;
    border-color: #F3F0EB !important;
}
</style>
