<template>
    <b-navbar type="dark" variant="dark" class="px-3">
      <b-navbar-brand href="#">
        <img class="navbar-logo" src="../assets/foryou_logo.png" alt="ForYou Logo">
      </b-navbar-brand>

      <b-collapse id="nav-collapse" is-nav>
        <!--
        <b-navbar-nav>
          <b-nav-item href="#">Link</b-nav-item>
          <b-nav-item href="#" disabled>Disabled</b-nav-item>
        </b-navbar-nav>
        -->

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-form>
            <div><b-button variant="primary" @click="logout()">Logout</b-button></div>
          </b-nav-form>
        </b-navbar-nav>

      </b-collapse>
    </b-navbar>
</template>

<script>
export default {
  name: 'HeaderComponent',
  methods: {
    logout () {
      sessionStorage.clear()
      this.$store.commit('resetState')
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.header {
  padding: 20px;
  position: relative;
}

.navbar {
  background-color: #2e2e2e !important;
}

.navbar-logo {
  width: 50px;
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
    background-color: #EC5500 !important;
    border-color: #F3F0EB !important;
}
</style>
