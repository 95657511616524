<template>
  <div class="container-fluid px-0">
    <LoginView :deal-id="dealId" v-if="!$store.getters.isAuthenticated"/>
    <div v-else class="home">

    <HeaderComponent />

      <DashboardView :deal-id="dealId"/>
      <div class="mx-2" style="background-color: #F3F0EB">
      <FooterComponent />
      </div>
    </div>
  </div>
</template>

<script>

import DashboardView from '@/views/DashboardView'
import LoginView from '@/components/LoginComponent'
import HeaderComponent from '@/components/HeaderComponent'
import FooterComponent from '@/components/FooterComponent'

export default {
  name: 'HomeView',
  components: {
    LoginView,
    DashboardView,
    HeaderComponent,
    FooterComponent
  },
  data () {
    return {
      dealId: ''
    }
  },
  beforeMount () {
    this.dealId = this.$route.params.id
  }
}
</script>

<style scoped>

</style>
