import { render, staticRenderFns } from "./BarChartComponent.vue?vue&type=template&id=d71b513e&scoped=true&"
import script from "./BarChartComponent.vue?vue&type=script&lang=js&"
export * from "./BarChartComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d71b513e",
  null
  
)

export default component.exports