<template>
  <div class="d-flex justify-content-center">
    <b-spinner style="width: 5rem; height: 5rem; background-color: #EC5500" variant="primary" type="grow" label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'LoadingComponent'
}
</script>

<style scoped>

</style>
