<template>
  <b-card
      :title="this.getHeadline"
      footer-tag="footer"
  >
    <b-card-text>
      Status: {{deal.Deal_Phase}}<br>
      Betrag: {{this.getBetrag}}<br>
      {{ getDesc(deal) }}
    </b-card-text>
    <template #footer>{{deal.Pipeline}}<span v-html="getErstellt(deal)"></span></template>
  </b-card>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DealComponent',
  data () {
    return {
      datum: null
    }
  },
  props: ['deal'],
  beforeMount () {
    moment.locale('de')
    this.datum = moment(String(this.deal.Datum_der_letzten_Aenderung)).format('DD.MM.YYYY')
  },
  computed: {
    getBetrag () {
      if (this.deal.Betrag !== null && this.deal.Betrag !== 0) {
        return parseInt(this.deal.Betrag, 10).toLocaleString('de-DE') + '€'
      }
      return 'Offen'
    },
    getHeadline () {
      if (this.deal.Associated_Company !== null && this.deal.Associated_Company !== '') {
        // return this.deal.Associated_Company
        return this.deal.Deal_Name
      }
      return this.deal.Deal_Name
    }
  },
  methods: {
    getDesc (deal) {
      if (deal.Kategorie === '-2') {
        if (deal.Deal_lost_grund == null) {
          return 'Grund: ' + '-'
        } else {
          return 'Grund: ' + deal.Deal_lost_grund
        }
      } else {
        return ''
      }
    },
    getErstellt (deal) {
      if ((deal.Pipeline === 'Outboundd' && deal.Kategorie === '-1')) {
        return '<br><em>Erstellt: ' + moment(String(this.deal.Datum_der_letzten_Aenderung)).format('DD.MM.YYYY') + '</em>'
      }
    }
  }
}
</script>

<style scoped>

</style>
