<template>
  <div class="login-page wallpaper-login">
    <b-container fluid>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-8 mx-auto">
          <div class="card login">
            <h1>Login</h1>
            <form class="form-group">
              <b-form-input v-model="password" class="form-control" type="password" placeholder="Passwort eingeben"></b-form-input>
              <b-button type="submit" @click="login" variant="outline-primary">Anmelden</b-button>
              <b-alert v-model="errored" class="errored" variant="danger" dismissible>
                <!--Fehler: {{error}}-->
                Fehler:<br>Falsches Passwort. Bitte erneut versuchen.
              </b-alert>
            </form>
          </div>
          <div class="card" style="margin-top: 30px;">
            <FooterComponent />
            </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { loginUser } from '@/client'
import FooterComponent from '@/components/FooterComponent'

export default {
  name: 'LoginView',
  props: ['dealId'],
  components: {
    FooterComponent
  },
  data () {
    return {
      password: '',
      errored: false,
      error: null
    }
  },
  methods: {
    login () {
      loginUser(this.dealId, this.password, (sessionToken, error) => {
        if (error) {
          this.errored = true
          this.error = error
        }
        if (sessionToken) {
          this.$store.dispatch('setUserToken', sessionToken.substring(1))
          this.$store.dispatch('setUserId', this.dealId)
        }
      })
    }
  }
}
</script>

<style scoped>
p {
  line-height: 1rem;
}

.card {
  padding: 20px;
}

.form-group {
  margin-bottom: unset;
}

.form-group button {
  width: 100%;
}

.form-group input {
  margin-top: 20px;
  margin-bottom: 20px;
}

.wallpaper-login {
  background: url('../assets/pexels-stephan-seeber-1261728_blur.jpg') no-repeat center center;
  background-size: cover;
}

.container-fluid{
padding-left: 0 !important;
padding-right: 0 !important;
}

.login-page {
  align-items: center;
  display: flex;
  height: 100vh;
}

.form-group div[role="alert"] {
  margin-top: 20px;
}

.errored {
   animation-name: errorShake;
   animation-duration: 0.4s;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

@keyframes errorShake {
   0% {
      transform: translateX(-25px);
   }
   25% {
      transform: translateX(25px);
   }
   50% {
      transform: translateX(-25px);
   }
   75% {
      transform: translateX(25px);
   }
   100% {
      transform: translateX(0);
   }
}
.btn-outline-primary {
    border-color: #EC5500 !important;
    color: #EC5500 !important;
}

.btn-outline-primary:hover {
  background-color: #EC5500 !important;
  color: #FFF !important;
}
</style>
